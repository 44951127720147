$(document).ready(function() {
  $('.triggerTransferPersonToCourse').click(function() {
    $('input#waitinglistitemId').val($(this).data('waitinglistitemid'));

    $('.select2-ajax').select2({
      language: 'de',
      ajax: {
        url: $(this).data('getcourses'),
        dataType: 'json',
        delay: 500,
        headers: {
          'Accept': 'application/json',
        },
        processResults: function(data) {
          // Transforms the top-level key of the response object from 'items' to 'results'
          return {
            results: data,
          };
        },
      },
    });
  });
});
