import $ from 'jquery';

$(document).ready(function() {

  function txt(element) {
    return $.trim(element.text());
  }

  $('select.attendanceStatusSelect').each(function() {
    const select = $(this);
    const label = $('label[for=\'' + select.attr('id') + '\']');

    function selectedOption() {
      return select.children('option:selected');
    }

    function updateLabel() {
      label.removeClass('btn-warning');
      label.removeClass('btn-danger');
      label.removeClass('btn-success');

      switch (selectedOption().val()) {
        case '1':
          label.addClass('btn-success');
          break;
        case '2':
          label.addClass('btn-warning');
          break;
        case '3':
          label.addClass('btn-danger');
          break;
      }
    }

    select.hide();
    updateLabel(label);
    label.prop('title', txt(selectedOption()));

    label.click(function() {
      let next = selectedOption().next();

      if (next.length === 0) {
        next = select.children('option:first');
      }

      next.prop('selected', 'true');
      label.prop('title', txt(next));

      updateLabel(label);
    });
  });
});
