import $ from 'jquery';
global.$ = global.jQuery = $;

import 'bootstrap';
import 'bootstrap4-toggle';

import 'select2';
import 'select2/dist/css/select2.css';
import 'jquery-ui-bundle';

// custom javascript
import './js/form/course/addEditCourse';
import './js/modal/addPersonToCourse';
import './js/modal/addPersonToWaitinglist';
import './js/waitinglist/assignPersonToWaitinglist';
import './js/waitinglist/assignPersonToCourse';
import './js/attendenceReport/newEdit';

// start the Stimulus application
import './bootstrap';

// Stylesheets
import './scss/admin.scss';
