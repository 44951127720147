$('.triggerAddPersonToCourseModal').click(function() {
  $('#createNewPerson').attr('href', $(this).data('linkfornewperson'));
  $('input#courseId').val($(this).data('courseid'));

  const url = $('#triggerAddPersonToCourseModal').data('url');

  $('.select2-ajax').select2({
    language: 'de',
    ajax: {
      url: url,
      dataType: 'json',
      delay: 500,
      headers: {
        'Accept': 'application/json',
      },
      processResults: function(data) {
        return {
          results: data,
        };
      },
      data: function(params) {
        return {
          searchword: params.term,
        };
      },
    },
  });

  $('.select2-ajax').on('select2:select', function(e) {
    $('button#addPersonToCourse').removeAttr('disabled');
  });
});
