$(document).ready(function() {
  $('.person-item').draggable({
    revert: 'invalid',
    cursor: 'move',
    helper: 'clone',
  });

  $('.dropzone').droppable({
    drop: function(event, ui) {
      const waitinglistitemId = ui.draggable[0].id;
      const courseId = $(this)[0].id;

      const url = $('#assignData').data('url');

      ui.draggable.remove();
      ajax(url, waitinglistitemId, courseId);
    },
    over: function(event, ui) {
      $(this).css('background', 'orange');
    },
    out: function(event, ui) {
      $(this).css('background', 'transparent');
    },
  });
});

function ajax(url, waitinglistitemId, courseId) {
  $.ajax({
    url: url,
    type: 'POST',
    data: {
      'waitinglistitemId': waitinglistitemId,
      'courseId': courseId,
      'ajax': true,
    },
    dataType: 'json',
    success: function(data) {
      location.reload();
    },
    error: function(request, error) {
    },
  });
}
