$('.triggerAddPersonToWaitinglistModal').click(function() {
  $('#createNewPerson').attr('href', $(this).data('linkfornewperson'));
  $('input#waitinglistId').val($(this).data('waitinglistid'));

  const url = $('#triggerAddPersonToWaitinglistModal').data('url');

  $('.select2-ajax').select2({
    language: 'de',
    ajax: {
      url: url,
      dataType: 'json',
      delay: 500,
      headers: {
        'Accept': 'application/json',
      },
      processResults: function(data) {
        return {
          results: data,
        };
      },
      data: function(params) {
        return {
          searchword: params.term,
        };
      },
    },
  });
});
