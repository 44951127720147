$(document).ready(function() {
  const typeSelect = $('select[name="course[type]"]');

  updateFormfieldsForSelectedType(typeSelect.val());

  typeSelect.change(function() {
    updateFormfieldsForSelectedType($(this).val());
  });

  function updateFormfieldsForSelectedType(type) {
    switch (type) {
      case 'regular':
        typeRegularSelected();
        break;
      case 'course':
        typeCourseSelected();
        break;
      case 'special':
        typeSpecialSelected();
        break;
      case 'chargable':
        typePaidCourseSelected();
        break;
    }
  }

  function typeRegularSelected() {
    hideFieldEndday(true);
    hideFieldPrice(true);
    hideFieldDay(true);
    uncheckRegistrationNeeded();

    showFieldWeekday();
  }

  function typePaidCourseSelected() {
    showFieldEndday(true);
    showFieldWeekday(true);
    checkRegistrationNeeded();

    showFieldDay();
    showFieldPrice();
  }

  function typeCourseSelected() {
    hideFieldEndday(true);
    hideFieldPrice(true);
    hideFieldWeekday(true);
    checkRegistrationNeeded();

    showFieldDay();
  }

  function typeSpecialSelected() {
    hideFieldPrice(true);
    hideFieldWeekday(true);
    checkRegistrationNeeded();

    showFieldDay();
    showFieldEndday();
  }

  function checkRegistrationNeeded() {
    $('#course_registrationNeeded').attr('checked', true);
  }

  function uncheckRegistrationNeeded() {
    $('#course_registrationNeeded').attr('checked', false);
  }

  function showFieldDay() {
    const dayField = $('#course_day');
    dayField.parent().parent().removeClass('d-none');
    dayField.prop('required', true);
  }

  function hideFieldDay(reset = false) {
    const dayField = $('#course_day');
    dayField.parent().parent().addClass('d-none');
    dayField.prop('required', false);

    if (reset) {
      dayField.val('');
    }
  }

  function showFieldWeekday() {
    const weekdayField = $('#course_weekday');
    weekdayField.parent().parent().removeClass('d-none');
    weekdayField.prop('required', true);
  }

  function hideFieldWeekday(reset = false) {
    const weekdayField = $('#course_weekday');
    weekdayField.parent().parent().addClass('d-none');
    weekdayField.prop('required', false);

    if (reset) {
      weekdayField.val('');
    }
  }

  function showFieldEndday() {
    const enddayField = $('#course_endday');
    enddayField.parent().parent().removeClass('d-none');
    enddayField.prop('required', true);
  }

  function hideFieldEndday(reset = false) {
    const enddayField = $('#course_endday');
    enddayField.parent().parent().addClass('d-none');
    enddayField.prop('required', false);

    if (reset) {
      enddayField.val('');
    }
  }

  function showFieldPrice() {
    const priceField = $('#course_price');
    priceField.parent().parent().removeClass('d-none');
    priceField.prop('required', true);
  }

  function hideFieldPrice(reset = false) {
    const priceField = $('#course_price');
    priceField.parent().parent().addClass('d-none');
    priceField.prop('required', false);

    if (reset) {
      priceField.val('');
    }
  }
});
